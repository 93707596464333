.fullscreen {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

#modal-body {
  max-height: 87vh;
  overflow-y: auto;
}

.artist-card-image {
  border-radius: 0 !important;
  cursor: pointer;
}

.btn.text-center {
  border-radius: 0 !important;
}
