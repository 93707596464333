/*
    This styling is heavily inspired by the Wikipedia Map project by Luke Deen Taylor

    Site: luke.deentaylor.com/wikipedia/
    Github: https://github.com/controversial/wikipedia-map
*/

#form-box {
    padding: 0;
    display: block;
    overflow: hidden;
  
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
  
    box-shadow: 0 4px 4px -2px #ccc;
}
  
#form-box button {
    background-color: rgba(255, 255, 255, 0.6);
  
    /* Border */
    outline: none;
  
    padding: 5px 7px;
    margin: 0;
    font-size: 18px;
    float: right;
    height: 40px;
    min-width: 33px;
}

#search-field {
    display: flex;
    flex-wrap: wrap;

    overflow: hidden;
    height: 33px;
    padding: 3px;
    margin: 0;

    font-family: "Helvetica Neue", Helvetica, sans-serif;
}

#search-field input {
    font-size: 1em;

    /* Kill default styles */
    outline: none;
    border: none;
    box-shadow: none;
    -webkit-appearance: none;
    background-color: transparent;

    margin: 2px 5px 3px 0;
    padding: 0 5px;
    flex: 2;
    height: 95%;
}
  
.transparent-blur {
    background-color: rgba(255, 255, 255, 0.65);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
}